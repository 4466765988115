import React, { useContext } from 'react';
import Box from '@mui/material/Box';
import TabbedPage, { Page } from '../../../common/TabbedPage';
import EventLogTab from './EventLogTab';
import DeviceHeaderInfo from '../common/DeviceHeaderInfo';
import Typography from '@mui/material/Typography';
import { COMPONENT_PADDING } from '../../../../themes/theme';
import { BasicLogInfo, LogChart, LogChartOptions, BackendDevice } from '../../../../model/backendDataModels';
import InstantChartTab from './InstantChartTab';
import HistoryChartTab from './HistoryChartTab';
import HistoryLogTab from './HistoryLogTab';
import { useBasicLogInfo, useHistoryChart, useHistoryChartOptions, useInstantChart } from '../../../../dataHooks/deviceHooks';
import { SWRResponse } from 'swr';
//import ProtectiveLogTab from './ProtectiveLogTab';
//import { useBmsBasicLogInfo } from '../../../../dataHooks/bmsHooks';
import { AppContext } from '../../../../App';
import LoadingIndicator from '../../../common/LoadingIndicator';
import { BackendError } from '../../../../utils/BackendError';
import moment from 'moment-timezone';
import ServiceLogTab from './ServiceLogTab';
import { LoginContext } from '../../../../Login';

function createPages(treeDevice: BackendDevice, accessToken: string | null): Page[] {
  const pages: Page[] = [];

  function getHistoryChartDataHook(from: Date, to: Date): SWRResponse<LogChart, BackendError> {
    return useHistoryChart(treeDevice.productCategory === 'bmu' ? 'bmu' : 'charger', treeDevice.mui, from, to, accessToken);
  }

  function getHistoryChartOptionsDataHook(): SWRResponse<LogChartOptions, BackendError> {
    return useHistoryChartOptions(treeDevice.productCategory === 'bmu' ? 'bmu' : 'charger', accessToken);
  }

  function getInstantChartDataHook(from: Date, to: Date): SWRResponse<LogChart, BackendError> {
    return useInstantChart(treeDevice.productCategory === 'bmu' ? 'bmu' : 'charger', treeDevice.mui, from, to, accessToken);
  }

  if (treeDevice.productCategory === 'bmu' || treeDevice.productCategory === 'bms' || treeDevice.productCategory === 'charger') {
    pages.push({
      name: 'History log',
      body: <HistoryLogTab mui={treeDevice.mui} serialNumber={treeDevice.serialNumber} productCategory={treeDevice.productCategory} />,
    });
  }

  if (treeDevice.productCategory === 'bmu' || treeDevice.productCategory === 'bms' || treeDevice.productCategory === 'charger') {
    pages.push({
      name: 'History chart',
      body: <HistoryChartTab dataHook={getHistoryChartDataHook} optionsHook={getHistoryChartOptionsDataHook} />,
    });
  }

  if (treeDevice.productCategory === 'bmu' || treeDevice.productCategory === 'bms' || treeDevice.productCategory === 'charger') {
    pages.push({
      name: 'Event log',
      body: <EventLogTab mui={treeDevice.mui} serialNumber={treeDevice.serialNumber} productCategory={treeDevice.productCategory} />,
    });
  }

  // if (treeDevice.productCategory === 'bms') {
  //   pages.push({
  //     name: 'Protective log',
  //     body: <ProtectiveLogTab mui={treeDevice.mui} serialNumber={treeDevice.serialNumber} />,
  //   });
  // }

  if (treeDevice.productCategory === 'bmu' || treeDevice.productCategory === 'bms' || treeDevice.productCategory === 'charger') {
    pages.push({
      name: 'Instant chart',
      body: <InstantChartTab dataHook={getInstantChartDataHook} productCategory={treeDevice.productCategory} />,
    });
  }

  pages.push({
    name: 'Service log',
    body: <ServiceLogTab mui={treeDevice.mui} />,
  });

  return pages;
}

type LogFilesTabHeaderProps = {
  treeDevice: BackendDevice;
  tabName: string;
};

function LogFilesTabHeader({ treeDevice, tabName }: LogFilesTabHeaderProps): JSX.Element | null {
  const loginContext = useContext(LoginContext);
  const appContext = useContext(AppContext);

  const { data: basicLogsInfo, error } = useBasicLogInfo(treeDevice.mui, loginContext.accessToken);
  // const { data: basicLogsInfo, error } =
  //   treeDevice.productCategory === 'bms'
  //     ? useBmsBasicLogInfo(treeDevice.mui, loginContext.accessToken)
  //     : useBasicLogInfo(treeDevice.mui, loginContext.accessToken);
  let content = null;

  if (error) {
    content = null;
  } else if (basicLogsInfo) {
    let latestHistoryLogUpdate = moment(basicLogsInfo.latestHistoryLogUpdate, moment.ISO_8601)
      .tz(appContext.timezone)
      .format('YYYY-MM-DD HH:mm:ss');
    let latestEventLogUpdate = moment(basicLogsInfo.latestEventLogUpdate, moment.ISO_8601)
      .tz(appContext.timezone)
      .format('YYYY-MM-DD HH:mm:ss');

    if (latestHistoryLogUpdate.includes('Invalid date')) {
      latestHistoryLogUpdate = '';
    }

    if (latestEventLogUpdate.includes('Invalid date')) {
      latestEventLogUpdate = '';
    }

    // if (treeDevice.productCategory === 'bms') {
    //   const bmsBasicLogInfo = basicLogsInfo as BmsBasicLogInfo;

    //   let latestProtectiveLogUpdate = moment(bmsBasicLogInfo.latestProtectiveLogUpdate, moment.ISO_8601)
    //     .tz(appContext.timezone)
    //     .format('YYYY-MM-DD HH:mm:ss');

    //   if (latestProtectiveLogUpdate.includes('Invalid date')) {
    //     latestProtectiveLogUpdate = '';
    //   }

    //   content = (
    //     <>
    //       <DeviceHeaderInfo label='Serial number' text={basicLogsInfo.serialNumber} />

    //       {tabName.startsWith('History') && <DeviceHeaderInfo label='Latest history log update' text={latestHistoryLogUpdate} />}
    //       {tabName.startsWith('Event') && <DeviceHeaderInfo label='Latest event log update' text={latestEventLogUpdate} />}
    //       {tabName.startsWith('Protective') && <DeviceHeaderInfo label='Latest protective log update' text={latestProtectiveLogUpdate} />}
    //     </>
    //   );
    // } else {
    const basicLogInfo = basicLogsInfo as BasicLogInfo;

    let latestInstantLogUpdate = moment(basicLogInfo.latestInstantLogUpdate, moment.ISO_8601)
      .tz(appContext.timezone)
      .format('YYYY-MM-DD HH:mm:ss');

    if (latestInstantLogUpdate.includes('Invalid date')) {
      latestInstantLogUpdate = '';
    }

    content = (
      <>
        <DeviceHeaderInfo label='Serial number' text={basicLogsInfo.serialNumber} />

        {tabName.startsWith('History') && <DeviceHeaderInfo label='Latest history log update' text={latestHistoryLogUpdate} />}
        {tabName.startsWith('Event') && <DeviceHeaderInfo label='Latest event log update' text={latestEventLogUpdate} />}
        {tabName.startsWith('Instant') && <DeviceHeaderInfo label='Latest instant log update' text={latestInstantLogUpdate} />}
      </>
    );
    // }
  } else {
    content = <LoadingIndicator />;
  }

  if (error) {
    appContext.addBackendError(error);
  }

  return <Box sx={{ display: 'flex', flexDirection: 'row' }}>{content}</Box>;
}

type LogFilesTabProps = {
  treeDevice: BackendDevice;
  tabMode: boolean;
  selectedTabName: string;
  setSelectedTabName: (selectedTabName: string) => void;
};

export default function LogFilesTab(props: LogFilesTabProps): JSX.Element {
  const loginContext = useContext(LoginContext);

  const pages = createPages(props.treeDevice, loginContext.accessToken);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: props.tabMode ? '100%' : '100vh', borderTop: '2px solid black' }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'start',
          padding: `${COMPONENT_PADDING}px`,
          paddingBottom: 0,
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'end' }}>
          <Typography variant='bigHeader' component='div' sx={{ marginBottom: '15px' }}>
            Logfiles
          </Typography>
        </Box>
        {props.selectedTabName && <LogFilesTabHeader treeDevice={props.treeDevice} tabName={props.selectedTabName} />}
      </Box>
      <TabbedPage pages={pages} disableScroll={true} selectedPage={props.selectedTabName} setSelectedPage={props.setSelectedTabName} />
    </Box>
  );
}
