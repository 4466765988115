import React, { useContext, useState } from 'react';
import Box from '@mui/material/Box';
import AlternatingColorsList from '../common/AlternatingColorsList';
import Typography from '@mui/material/Typography';
import Switch from '@mui/material/Switch';
import { COMPONENT_PADDING } from '../../../themes/theme';
import LoadingIndicator from '../../common/LoadingIndicator';
import { useBasicLogInfo, useDeviceConfig } from '../../../dataHooks/deviceHooks';
import InputRow from './logFilesTab/common/InputRow';
import InputComponent from '../../common/InputComponent';
import { BackendDevice, FieldsWithSingleValues } from '../../../model/backendDataModels';
import { AppContext } from '../../../App';
import DeviceHeaderInfo from './common/DeviceHeaderInfo';
//import { useBmsBasicLogInfo } from '../../../dataHooks/bmsHooks';
import moment from 'moment-timezone';
import DefaultLoadingButton from '../../common/DefaultLoadingButton';
import { LoginContext } from '../../../Login';
import { generateConfigurationExport } from '../../../services/generateLogExport';
import { useDownloadFile } from '../../../dataHooks/downloadHooks';
import { toTemperatureString } from '../../../utils/temperatureUtils';

type ConfigurationTabHeaderProps = {
  selectedDevice: BackendDevice;
};

type ConfigurationTabProps = {
  selectedDevice: BackendDevice;
};

function mergeObjectData(fieldsAndValues: FieldsWithSingleValues, timezone: string, displayFahrenheit: boolean): FieldsWithSingleValues {
  const values: string[] = [];

  fieldsAndValues.fields.forEach((field, index) => {
    if (field.type === 'datetime') {
      const date = moment(fieldsAndValues.values[index], moment.ISO_8601).tz(timezone);
      const dateString = date.format('YYYY-MM-DD HH:mm:ss');

      values.push(dateString);
    } else if (field.type === 'boolean') {
      values.push(fieldsAndValues.values[index] ? 'Active' : 'Inactive');
    } else if (field.type === 'number') {
      values.push(fieldsAndValues.values[index].toString());
    } else if (field.type === 'temperature') {
      values.push(toTemperatureString(fieldsAndValues.values[index] as number, displayFahrenheit, 2));
    } else {
      values.push(fieldsAndValues.values[index] as string);
    }
  });

  return {
    fields: fieldsAndValues.fields,
    values: values,
  };
}

export function ConfigurationTabHeader({ selectedDevice }: ConfigurationTabHeaderProps): JSX.Element {
  const loginContext = useContext(LoginContext);
  const appContext = useContext(AppContext);

  const [generating, setGenerating] = useState(false);

  const { data, error } = useBasicLogInfo(selectedDevice.mui, loginContext.accessToken);

  // const { data, error } =
  //   selectedDevice.productCategory === 'bms'
  //     ? useBmsBasicLogInfo(selectedDevice.mui, loginContext.accessToken)
  //     : useBasicLogInfo(selectedDevice.mui, loginContext.accessToken);

  let content = null;
  let latestConfigLogUpdate = '';

  if (error) {
    content = null;
  } else if (data) {
    const timestampDate = moment(data.latestConfigLogUpdate, moment.ISO_8601).tz(appContext.timezone);
    latestConfigLogUpdate = timestampDate.format('YYYY-MM-DD HH:mm:ss');

    content = (
      <>
        <DeviceHeaderInfo label='Serial number' text={data.serialNumber} />
        <DeviceHeaderInfo label='Latest configuration log update' text={latestConfigLogUpdate} />
      </>
    );
  } else {
    content = <LoadingIndicator />;
  }

  const preDownloading = (): void => setGenerating(true);
  const postDownloading = (): void => setGenerating(false);
  const onErrorDownloadFile = (): void => {
    setGenerating(false);
  };
  const getFileName = (): string => {
    return `GET Logs config ${selectedDevice.serialNumber} ${latestConfigLogUpdate}.xlsx`;
  };
  const downloadFile = (): Promise<Response> => {
    const token = loginContext.accessToken || '';

    return generateConfigurationExport(selectedDevice.productCategory, selectedDevice.mui, token, appContext.timezone);
  };
  const { download } = useDownloadFile({
    apiDefinition: downloadFile,
    preDownloading,
    postDownloading,
    onError: onErrorDownloadFile,
    getFileName,
  });

  if (error) {
    appContext.addBackendError(error);
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'start',
        paddingBottom: 0,
      }}
    >
      <InputRow>
        <InputComponent label=''>
          <DefaultLoadingButton
            sx={{
              height: '40px',
            }}
            loading={generating}
            onClick={download}
          >
            Export data
          </DefaultLoadingButton>
        </InputComponent>
      </InputRow>
      <Box sx={{ display: 'flex', flexDirection: 'row' }}>{content}</Box>
    </Box>
  );
}

export default function ConfigurationTab({ selectedDevice }: ConfigurationTabProps): JSX.Element {
  const loginContext = useContext(LoginContext);
  const appContext = useContext(AppContext);

  const [showAdvanced, setShowAdvanced] = useState(false);

  const { data, error } = useDeviceConfig(selectedDevice, loginContext.accessToken);

  if (error) {
    appContext.addBackendError(error);
  }

  if (error) {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', borderTop: '2px solid black' }}>
        <Box sx={{ padding: `${COMPONENT_PADDING}px`, height: '100%', overflow: 'auto' }}>
          <Typography variant='bigHeader' component='div' sx={{ marginBottom: '15px' }}>
            Configuration Tab
          </Typography>
        </Box>
      </Box>
    );
  }

  if (!data) {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', borderTop: '2px solid black' }}>
        <Box sx={{ padding: `${COMPONENT_PADDING}px`, height: '100%', overflow: 'auto' }}>
          <Typography variant='bigHeader' component='div' sx={{ marginBottom: '15px' }}>
            Configuration Tab
          </Typography>
          <LoadingIndicator />
        </Box>
      </Box>
    );
  }

  const basic = mergeObjectData(data.basic, appContext.timezone, appContext.displayFahrenheit);
  const advanced = mergeObjectData(data.advanced, appContext.timezone, appContext.displayFahrenheit);

  const basicData = basic.fields.map(function (e, i) {
    return [e.name, basic.values[i]];
  });

  const advancedData = advanced.fields.map(function (e, i) {
    return [e.name, advanced.values[i]];
  });

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', borderTop: '2px solid black' }}>
      <Box sx={{ padding: `${COMPONENT_PADDING}px`, height: '100%', overflow: 'auto' }}>
        <ConfigurationTabHeader selectedDevice={selectedDevice} />
        <Typography variant='bigHeader' component='div' sx={{ marginBottom: '15px' }}>
          Configuration Tab
        </Typography>
        <AlternatingColorsList data={basicData} />
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', width: '100%', marginTop: '10px' }}>
          <Typography variant='columnHeader' sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            Advanced settings
          </Typography>
          <Switch checked={showAdvanced} onChange={(event, checked): void => setShowAdvanced(checked)} color='info' />
        </Box>
        {showAdvanced && (
          <>
            <Typography variant='graphHeader' component='div'>
              Advanced
            </Typography>
            <AlternatingColorsList data={advancedData} />
          </>
        )}
      </Box>
    </Box>
  );
}
