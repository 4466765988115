import React, { useContext } from 'react';
import Box from '@mui/material/Box';
import {
  BackendDevice,
  BluOverviewStatus,
  BmsOverviewStatus,
  BmuOverviewStatus,
  ChargerOverviewStatus,
  //instanceOfBmsOverviewStatus,
  instanceOfBmuOverviewStatus,
} from '../../../../model/backendDataModels';
import TopRightHeader from './TopRightHeader';
import DeviceType from './DeviceType';
import IdentifierAndFleet from './IdentifierAndFleet';
import { AppContext } from '../../../../App';
import { BackendError } from '../../../../utils/BackendError';
import { SWRResponse } from 'swr';
import LoadingIndicator from '../../../common/LoadingIndicator';
import Identifier from './Identifier';
import ExpandViewSwitch from '../../../common/ExpandViewSwitch';
import { LoginContext } from '../../../../Login';

type CommonHeaderProps = {
  selectedDevice: BackendDevice;
  dataHook: (
    mui: string,
    accessToken: string | null
  ) =>
    | SWRResponse<BmsOverviewStatus, BackendError>
    | SWRResponse<BmuOverviewStatus, BackendError>
    | SWRResponse<BluOverviewStatus, BackendError>
    | SWRResponse<ChargerOverviewStatus, BackendError>;
  onFullscreenChanged: (fullscreen: boolean) => void;
  fullscreen: boolean;
  deviceInitialized: boolean;
};

export default function CommonHeader(props: CommonHeaderProps): JSX.Element {
  const loginContext = useContext(LoginContext);
  const appContext = useContext(AppContext);

  const { data: status, error, isLoading } = props.dataHook(props.selectedDevice.mui, loginContext.accessToken);

  if (error) {
    appContext.addBackendError(error);
    return <></>;
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
      {status && (
        <>
          <Box sx={{ display: 'flex', flexDirection: 'column', marginTop: '3px' }}>
            <DeviceType status={status} type={props.selectedDevice.productCategory} />
            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
              {props.deviceInitialized && (
                <IdentifierAndFleet
                  identifierType={'Device ID'}
                  identifier={status.deviceId}
                  // identifierType={props.selectedDevice.productCategory === 'bms' ? 'Serial Number' : 'Device ID'}
                  // identifier={instanceOfBmsOverviewStatus(status) ? status.serialNumber : status.deviceId}
                  fleetName={status.fleet}
                />
              )}
              {props.deviceInitialized && instanceOfBmuOverviewStatus(status) && (
                <Identifier identifierType={'Battery Tag'} identifier={props.selectedDevice.deviceTag} />
              )}
            </Box>
          </Box>

          <Box sx={{ marginTop: '20px' }}>
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', marginRight: '10px' }}>
              <ExpandViewSwitch wideMode={props.fullscreen} onChange={props.onFullscreenChanged} />
            </Box>

            <Box sx={{ marginRight: '30px' }}>{props.deviceInitialized && <TopRightHeader status={status} />}</Box>
          </Box>
        </>
      )}
      {isLoading && <LoadingIndicator sx={{ justifyContent: 'center', marginRight: '150px' }} />}
    </Box>
  );
}
