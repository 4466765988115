import React, { useContext, useState } from 'react';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { COMPONENT_PADDING } from '../../../../../themes/theme';
import EventLog from '../common/EventLog';
import DeletableTabTitle from '../common/DeletableTabTitle';
import { BackendError } from '../../../../../utils/BackendError';
import { SWRResponse } from 'swr';
import { useEquilizingLogs, useEquilizingMissed } from '../../../../../dataHooks/fleetAndFleetgroupHooks';
import WeeklyEqCompGraph from './WeeklyEqCompGraph';
import {
  BackendFleet,
  BackendFleetgroup,
  FieldsAndNumberValues,
  FieldsAndValues,
  isBackendFleetgroup,
} from '../../../../../model/backendDataModels';
import { utcToZonedTime } from 'date-fns-tz';
import moment, { Moment } from 'moment-timezone';
import Box from '@mui/material/Box';
import { LoginContext } from '../../../../../Login';
import { AppContext } from '../../../../../App';
import DeviceCompletedList from '../common/deviceMissedList/DeviceCompletedList';

type EqualizingTabProps = {
  selectedFleetOrFleetgroup: BackendFleet | BackendFleetgroup;
  deleteTab: () => Promise<void>;
};

export default function EqualizingTab({ selectedFleetOrFleetgroup, deleteTab }: EqualizingTabProps): JSX.Element {
  const loginContext = useContext(LoginContext);
  const appContext = useContext(AppContext);

  const date = utcToZonedTime(new Date(), appContext.timezone);

  const [year, setYear] = useState(date.getFullYear());
  const [month, setMonth] = useState(date.getMonth());

  const startDate = moment(new Date(year, month, 1)).startOf('day');
  const endDate = moment(startDate).add(1, 'month').subtract(1, 'second');

  const today = moment(date);
  const correctedEndDate = moment(endDate).isAfter(today) ? today : endDate;

  function getEqualizingLogsDataHook(startDate: Moment, endDate: Moment): SWRResponse<FieldsAndValues, BackendError> {
    return useEquilizingLogs(
      isBackendFleetgroup(selectedFleetOrFleetgroup) ? 'fleetgroup' : 'fleet',
      isBackendFleetgroup(selectedFleetOrFleetgroup) ? selectedFleetOrFleetgroup.fleetgroupId : selectedFleetOrFleetgroup.fleetId,
      startDate,
      endDate,
      loginContext.accessToken
    );
  }

  function getEquilizingMissedDataHook(startDate: Moment, endDate: Moment): SWRResponse<FieldsAndNumberValues, BackendError> {
    return useEquilizingMissed(
      isBackendFleetgroup(selectedFleetOrFleetgroup) ? 'fleetgroup' : 'fleet',
      isBackendFleetgroup(selectedFleetOrFleetgroup) ? selectedFleetOrFleetgroup.fleetgroupId : selectedFleetOrFleetgroup.fleetId,
      startDate,
      endDate,
      loginContext.accessToken
    );
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', borderTop: '2px solid black' }}>
      <Paper sx={{ padding: `${COMPONENT_PADDING}px` }}>
        <DeletableTabTitle title={'Equalizing'} deleteTab={deleteTab} />
        <Grid container spacing={`${COMPONENT_PADDING}px`}>
          <WeeklyEqCompGraph
            selectedFleetOrFleetgroup={selectedFleetOrFleetgroup}
            title='EQ Completion'
            startDate={startDate.toDate()}
            endDate={correctedEndDate.toDate()}
            onMonthSelected={setMonth}
            onYearSelected={setYear}
          />
          <DeviceCompletedList
            dataHook={getEquilizingMissedDataHook}
            title='Equalization Completion Log'
            startDate={startDate}
            endDate={correctedEndDate}
          />
          <EventLog dataHook={getEqualizingLogsDataHook} title='EQ Log' startDate={startDate} endDate={correctedEndDate} />
        </Grid>
      </Paper>
    </Box>
  );
}
