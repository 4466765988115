import React, { useState, useEffect, useRef } from 'react';
import { SxProps } from './commonTypes';
import SvgIcon from '@mui/material/SvgIcon';
import { ReactComponent as SvgDefault } from '../../icons/icon_info.svg';

async function loadIcon(
  name: string,
  SvgComponentRef: React.MutableRefObject<React.FC<React.SVGProps<SVGSVGElement>> | undefined>,
  postload: () => void
): Promise<void> {
  if (name in ['charger', 'access', 'lion', 'sharp', 'sc', 'scli', 'sclican']) {
    name = 'charger';
  } else if (name === 'bms') {
    name = 'battery';
  }

  switch (name) {
    case 'add':
      SvgComponentRef.current = (await import('../../icons/icon_add.svg')).ReactComponent;
      break;
    case 'alarms':
      SvgComponentRef.current = (await import('../../icons/icon_alarms.svg')).ReactComponent;
      break;
    case 'arrow':
      SvgComponentRef.current = (await import('../../icons/icon_arrow.svg')).ReactComponent;
      break;
    case 'battery':
      SvgComponentRef.current = (await import('../../icons/icon_battery.svg')).ReactComponent;
      break;
    case 'bmu':
      SvgComponentRef.current = (await import('../../icons/icon_bmu.svg')).ReactComponent;
      break;
    case 'browser':
      SvgComponentRef.current = (await import('../../icons/icon_browser.svg')).ReactComponent;
      break;
    case 'cal_arrow':
      SvgComponentRef.current = (await import('../../icons/icon_cal_arrow.svg')).ReactComponent;
      break;
    case 'charger':
      SvgComponentRef.current = (await import('../../icons/icon_charger.svg')).ReactComponent;
      break;
    case 'charging':
      SvgComponentRef.current = (await import('../../icons/icon_charging.svg')).ReactComponent;
      break;
    case 'discharging':
      SvgComponentRef.current = (await import('../../icons/icon_discharging.svg')).ReactComponent;
      break;
    case 'edit':
      SvgComponentRef.current = (await import('../../icons/icon_edit.svg')).ReactComponent;
      break;
    case 'eq':
      SvgComponentRef.current = (await import('../../icons/icon_eq.svg')).ReactComponent;
      break;
    case 'expand':
      SvgComponentRef.current = (await import('../../icons/icon_expand.svg')).ReactComponent;
      break;
    case 'fleet':
      SvgComponentRef.current = (await import('../../icons/icon_fleet.svg')).ReactComponent;
      break;
    case 'fleetgroup':
      SvgComponentRef.current = (await import('../../icons/icon_fleetgroup.svg')).ReactComponent;
      break;
    case 'fleetnolocation':
      SvgComponentRef.current = (await import('../../icons/icon_fleetnolocation.svg')).ReactComponent;
      break;
    case 'gateway':
      SvgComponentRef.current = (await import('../../icons/icon_gateway.svg')).ReactComponent;
      break;
    case 'info':
      SvgComponentRef.current = (await import('../../icons/icon_info.svg')).ReactComponent;
      break;
    case 'log':
      SvgComponentRef.current = (await import('../../icons/icon_log.svg')).ReactComponent;
      break;
    case 'menu_arrange':
      SvgComponentRef.current = (await import('../../icons/icon_menu_arrange.svg')).ReactComponent;
      break;
    case 'menu_report':
      SvgComponentRef.current = (await import('../../icons/icon_menu_report.svg')).ReactComponent;
      break;
    case 'menu_user':
      SvgComponentRef.current = (await import('../../icons/icon_menu_user.svg')).ReactComponent;
      break;
    case 'menu_view':
      SvgComponentRef.current = (await import('../../icons/icon_menu_view.svg')).ReactComponent;
      break;
    case 'minimize':
      SvgComponentRef.current = (await import('../../icons/icon_minimize.svg')).ReactComponent;
      break;
    case 'online':
      SvgComponentRef.current = (await import('../../icons/icon_online.svg')).ReactComponent;
      break;
    case 'openwindow':
      SvgComponentRef.current = (await import('../../icons/icon_openwindow.svg')).ReactComponent;
      break;
    case 'overview':
      SvgComponentRef.current = (await import('../../icons/icon_overview.svg')).ReactComponent;
      break;
    case 'soc':
      SvgComponentRef.current = (await import('../../icons/icon_soc.svg')).ReactComponent;
      break;
    case 'x':
      SvgComponentRef.current = (await import('../../icons/icon_x.svg')).ReactComponent;
      break;
    default:
      SvgComponentRef.current = SvgDefault;
      break;
  }

  postload();
}

type IconProps = {
  name: string;
  fontSize?: 'inherit' | 'large' | 'medium' | 'small';
};

export default function Icon({ name, sx, fontSize = 'inherit' }: IconProps & SxProps): JSX.Element {
  const SvgComponentRef = useRef<React.FC<React.SVGProps<SVGSVGElement>>>();
  const [loading, setLoading] = useState(false);
  const [checkLoadedIteration, setCheckLoadedIteration] = useState(0);

  useEffect(() => {
    let isMounted = true;
    setLoading(true);

    loadIcon(name, SvgComponentRef, () => {
      if (isMounted) {
        setLoading(false);
        setCheckLoadedIteration(checkLoadedIteration + 1);
      }
    });

    return (): void => {
      isMounted = false;
    };
  }, [name]);

  async function waitAndThenCheckLoaded(): Promise<void> {
    await new Promise(r => setTimeout(r, 10));
    if (!SvgComponentRef.current) {
      setCheckLoadedIteration(checkLoadedIteration + 1);
    }
  }

  useEffect(() => {
    waitAndThenCheckLoaded();
  }, [checkLoadedIteration]);

  if (!loading && SvgComponentRef.current) {
    const SvgComponent = SvgComponentRef.current;
    return (
      <SvgIcon sx={{ ...sx }} fontSize={fontSize}>
        <SvgComponent height='100%' width='100%' />
      </SvgIcon>
    );
  } else {
    return (
      <SvgIcon sx={{ ...sx }} fontSize={fontSize}>
        <SvgDefault height='100%' width='100%' />
      </SvgIcon>
    );
  }
}
