import React, { useContext, useState } from 'react';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { COMPONENT_PADDING } from '../../../../themes/theme';
import DailyRateGraph from '../DailyRateGraph';
import DeviceMissedList from './common/deviceMissedList/DeviceMissedList';
import EventLog from './common/EventLog';
import StateCalendarHelpBox from './common/dailyStateCalendar/StateCalendarHelpBox';
import DeletableTabTitle from './common/DeletableTabTitle';
import {
  BackendFleet,
  BackendFleetgroup,
  CalendarStateData,
  FieldsAndNumberValues,
  FieldsAndValues,
  isBackendFleetgroup,
  LineChartData,
} from '../../../../model/backendDataModels';
import { SWRResponse } from 'swr';
import { BackendError } from '../../../../utils/BackendError';
import {
  useSocDeepDischargeDaily,
  useSocDeepDischargeSum,
  useSocLogs,
  useSocLowestSoc,
} from '../../../../dataHooks/fleetAndFleetgroupHooks';
import Box from '@mui/material/Box';
import { utcToZonedTime } from 'date-fns-tz';
import moment, { Moment } from 'moment-timezone';
import DailyStateCalendar from './common/dailyStateCalendar/DailyStateCalendar';
import { LoginContext } from '../../../../Login';
import { AppContext } from '../../../../App';

type SOCTabProps = {
  selectedFleetOrFleetgroup: BackendFleet | BackendFleetgroup;
  deleteTab: () => Promise<void>;
};

export default function SOCTab({ selectedFleetOrFleetgroup, deleteTab }: SOCTabProps): JSX.Element {
  const loginContext = useContext(LoginContext);
  const appContext = useContext(AppContext);

  const date = utcToZonedTime(new Date(), appContext.timezone);

  const [year, setYear] = useState(date.getFullYear());
  const [month, setMonth] = useState(date.getMonth());

  const startDate = moment(new Date(year, month, 1)).startOf('day');
  const endDate = moment(startDate).add(1, 'month').subtract(1, 'second');
  const today = moment(date);
  const correctedEndDate = moment(endDate).isAfter(today) ? today : endDate;

  function getDeepDischargeDailyDataHook(startDate: Moment, endDate: Moment): SWRResponse<CalendarStateData, BackendError> {
    return useSocDeepDischargeDaily(
      isBackendFleetgroup(selectedFleetOrFleetgroup) ? 'fleetgroup' : 'fleet',
      isBackendFleetgroup(selectedFleetOrFleetgroup) ? selectedFleetOrFleetgroup.fleetgroupId : selectedFleetOrFleetgroup.fleetId,
      startDate,
      endDate,
      loginContext.accessToken
    );
  }

  function getDeepDischargeSumDataHook(startDate: Moment, endDate: Moment): SWRResponse<LineChartData, BackendError> {
    return useSocDeepDischargeSum(
      isBackendFleetgroup(selectedFleetOrFleetgroup) ? 'fleetgroup' : 'fleet',
      isBackendFleetgroup(selectedFleetOrFleetgroup) ? selectedFleetOrFleetgroup.fleetgroupId : selectedFleetOrFleetgroup.fleetId,
      startDate,
      endDate,
      loginContext.accessToken
    );
  }

  function getSocLogsDataHook(startDate: Moment, endDate: Moment): SWRResponse<FieldsAndValues, BackendError> {
    return useSocLogs(
      isBackendFleetgroup(selectedFleetOrFleetgroup) ? 'fleetgroup' : 'fleet',
      isBackendFleetgroup(selectedFleetOrFleetgroup) ? selectedFleetOrFleetgroup.fleetgroupId : selectedFleetOrFleetgroup.fleetId,
      startDate,
      endDate,
      loginContext.accessToken
    );
  }

  function getSocMissedDataHook(startDate: Moment, endDate: Moment): SWRResponse<FieldsAndNumberValues, BackendError> {
    return useSocLowestSoc(
      isBackendFleetgroup(selectedFleetOrFleetgroup) ? 'fleetgroup' : 'fleet',
      isBackendFleetgroup(selectedFleetOrFleetgroup) ? selectedFleetOrFleetgroup.fleetgroupId : selectedFleetOrFleetgroup.fleetId,
      startDate,
      endDate,
      loginContext.accessToken
    );
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', borderTop: '2px solid black' }}>
      <Paper sx={{ padding: `${COMPONENT_PADDING}px` }}>
        <DeletableTabTitle title={'State of Charge'} deleteTab={deleteTab} />
        <Grid container spacing={`${COMPONENT_PADDING}px`}>
          <DailyStateCalendar
            dataHook={getDeepDischargeDailyDataHook}
            title='Daily Deep Discharges'
            help={
              <StateCalendarHelpBox
                success='No deep discharged batteries'
                warning='Less than 10% of the batteries with one deep discharge cycle or more'
                error='More than 10% of the batteries with one deep discharge cycle or more'
              />
            }
            startDate={startDate}
            endDate={endDate}
            onMonthSelected={(month: number): void => {
              setMonth(month);
            }}
            onYearSelected={(year: number): void => {
              setYear(year);
            }}
          />

          <DailyRateGraph dataHook={getDeepDischargeSumDataHook} title='Deep Discharges' startDate={startDate} endDate={correctedEndDate} />
          <DeviceMissedList
            dataHook={getSocMissedDataHook}
            title='Deep Discharge Summary'
            startDate={startDate}
            endDate={correctedEndDate}
          />
          <EventLog dataHook={getSocLogsDataHook} title='SOC Log' startDate={startDate} endDate={correctedEndDate} />
        </Grid>
      </Paper>
    </Box>
  );
}
